import React, { useCallback, useState, useRef } from "react"
import { useDropzone } from "react-dropzone"
import tw, { styled, css } from "twin.macro"
import ReCAPTCHA from "react-google-recaptcha"
import { FaTimes } from "react-icons/fa"
import { motion } from "framer-motion"
import { graphql } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["studio"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ContactForm = () => {
  const { t } = useTranslation();
  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()
  const policyCheckboxRef = useRef()
  const recaptchaRef = useRef()
  const [images, setImages] = useState([])
  const handleNewImages = files => {
    if (images.length <= 3 - files.length) {
      setImages([...images, ...files])
    }
  }
  const handleSubmit = () => {
    const name = nameRef.current.value
    const email = emailRef.current.value
    let message = messageRef.current.value
    const newsletter = true;
    const recaptchaValue = recaptchaRef.current.getValue()

    // if (newsletter) {
    //   message =
    //     message +
    //     "\n\nWyrażam zgodę na otrzymywanie wiadomości marketingowych drogą mailową.\nEmail: " +
    //     email
    // }

    fetch("https://nemezink.pl/03248974y91234hjsdaf012340jhasdf.php", {
      method: "POST",
      body: JSON.stringify({
        images,
        name,
        email,
        message,
        newsletter,
        recaptchaValue,
      }),
    }).then(response => {
      handleDisplayAlert(response);
    })
  }

  const files = images.map(img => (
    <div
      key={img.id}
      tw="flex flex-row items-center"
      css={css`
        margin-right: 1.5vw;
        @media (max-width: 1024px) {
          font-size: 4vw;
        }
      `}
    >
      <motion.div
        whileHover={{
          scale: 1.1,
          color: "#e7e7e7",
          transition: { duration: 0.3 },
        }}
      >
        <FaTimes
          onClick={() => setImages(images.filter(e => e.id !== img.id))}
          css={css`
            font-size: 1vw;
            margin-right: 0.5vw;
            cursor: pointer;
            @media (max-width: 1024px) {
              font-size: 4vw;
            }
          `}
        />
      </motion.div>
      {img.fileName}
    </div>
  ))

  const [displayAlert, setDisplayAlert] = useState(false)
  const [alertVariant, setAlertVartiant] = useState("success")

  const handleDisplayAlert = response => {
    if (response?.status === 200) {
      setAlertVartiant("success")
      setDisplayAlert(true)
      clearForm();
      setTimeout(() => setDisplayAlert(false), 3200)
    } else {
      setAlertVartiant("failure")
      setDisplayAlert(true)
      setTimeout(() => setDisplayAlert(false), 3200)
    }
  }

  const clearForm = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
    policyCheckboxRef.current.checked = false;
    recaptchaRef.current.reset();
  };

  const isContactPage =
    typeof window !== "undefined" &&
    (window.location.pathname.startsWith("/contact") ||
      window.location.pathname.startsWith("/en/contact"));

  return (
    <Form>
      {
        isContactPage ?
          <h2 style={{ color: '#FFF', fontFamily: 'Bebas Neue', fontSize: '32px' }}><Trans>form</Trans></h2> :
          <h4 style={{ color: '#FFF', fontFamily: 'Bebas Neue', fontSize: '32px' }}><Trans>form</Trans></h4>
      }
      <FormInput placeholder={t('formName')} ref={nameRef}></FormInput>
      <FormInput placeholder={t('formEmail')} ref={emailRef}></FormInput>
      <FormMessage placeholder={t('formMessage')} ref={messageRef} />
      <StyledDropzone onAddedImages={handleNewImages} />

      <aside
        css={css`
          display: ${images.length === 0 ? "none" : "block"};
          @media (max-width: 1024px) {
            margin: 2vw 0vw;
          }
        `}
      >
        <div tw="w-full flex flex-row justify-between items-center">
          <h4
            css={css`
              color: #747474;
              font-family: "Bebas Neue";
              font-weight: 400;
              letter-spacing: 0.12vw;
              font-size: 1vw;
              margin-bottom: 0.5vw;

              @media (max-width: 1024px) {
                font-size: 4vw;
              }
            `}
          >
            Przesłane Pliki:
          </h4>
          <button
            css={css`
              color: #747474;
              border: 2px solid #747474;
              font-family: "Bebas Neue";
              line-height: 100%;
              padding: 0.2vw 0.8vw;
              font-weight: 500;
              font-size: 0.9vw;
              letter-spacing: 0.1em;
              @media (max-width: 1024px) {
                font-size: 4vw;
                padding: 0.2vw 1vw;
              }
            `}
            onClick={() => {
              setImages([])
            }}
          >
            Wyczyść
          </button>
        </div>
        <div
          tw="flex flex-row items-center"
          css={css`
            color: #747474;
            font-family: "Bebas Neue";
            font-weight: 400;
            letter-spacing: 0.12vw;
            font-size: 0.8vw;
          `}
        >
          {files}
        </div>
      </aside>
      <div
        tw="flex flex-row items-center justify-between"
        css={css`
          margin-top: 1vw;
        `}
      >
        <input
          ref={policyCheckboxRef}
          type="checkbox"
          css={css`
            margin-right: 1vw;
            @media (max-width: 1024px) {
              margin-right: 3vw;
            }
          `}
        />
        <FormInfo>
          <span
            css={css`
              color: red;
            `}
          >
            *{" "}
          </span>
          <Trans>formTos</Trans>
        </FormInfo>
      </div>
      {/* <div
        tw="flex flex-row items-center justify-between"
        css={css`
          margin-right: 1vw;
          @media (max-width: 1024px) {
            margin-right: 3vw;
          }
        `}
      >
        <input
          ref={newsletterCheckboxRef}
          type="checkbox"
          css={css`
            margin-right: 1vw;
            @media (max-width: 1024px) {
              margin-right: 3vw;
            }
          `}
        />
        <FormInfo>
          <Trans>formNewsletter</Trans>
        </FormInfo>
      </div> */}
      <div
        css={css`
          ${tw`flex flex-row justify-between items-stretch`}
          margin: 2vw 0px 1.1vw 0px;
          @media (max-width: 1024) {
            margin: 0vw 0vw 1.2vw 0vw;
            align-items: center;
          }
          @media (max-width: 760px) {
            ${tw`flex-col items-center`}
          }
        `}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcBpLoaAAAAAJmouc1yXsJ-5Uhb9LEI6bCZvM_C"
          data-theme="dark"
          theme="dark"
          css={css`
            margin: 0px;
          `}
        />
        <SubmitButton
          onClick={() => {
            if (policyCheckboxRef.current.checked) {
              handleSubmit()
            }
          }}
        >
          <Trans>formSubmit</Trans>
        </SubmitButton>
      </div>
      <Alert displayAlert={displayAlert}>
        {alertVariant === "success" ? (
          <AlertMessage><Trans>success</Trans></AlertMessage>
        ) : (
          <AlertMessage>
            <Trans>failure</Trans>
          </AlertMessage>
        )}
      </Alert>
    </Form>
  )
}

export default ContactForm

const Alert = styled.div`
  ${tw`fixed`}
  //display: ${({ displayAlert }) => (displayAlert ? "block" : "none")};
  z-index: 1000;
  left: 50%;
  transform: ${({ displayAlert }) =>
    displayAlert ? "translate(-50%, 0%)" : "translate(-50%,calc(2vw + 100%))"};
  visibility: ${({ displayAlert }) => (displayAlert ? "visible" : "hidden")};
  bottom: 2vw;
  background-color: #171717;

  max-width: 35vw;
  padding: 1vw 2vw;
  transition: all 0.4s ease-out;
  @media (max-width: 1024px) {
    width: 90vw;
    max-width: 90vw;
    bottom: 2vw;
    padding: 2vw 4vw;
  }
`
const AlertMessage = styled.span`
  font-family: "Space Mono";
  font-size: 0.9vw;
  color: #e7e7e7;
  @media (max-width: 1024px) {
    font-size: 3vw;
  }
`

var i = 1

function StyledDropzone({ onAddedImages }) {
  const onDrop = useCallback(
    async acceptedFiles => {
      const promises = []
      acceptedFiles.forEach(file => {
        promises.push(
          new Promise(resolve => {
            const reader = new FileReader()
            reader.onabort = () => { }
            reader.onerror = () => { }
            reader.onload = () => {
              // Do whatever you want with the file contents
              var base64 = btoa(
                new Uint8Array(reader.result).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )
              resolve({ base64, fileName: file.name, id: i++ })
            }
            reader.readAsArrayBuffer(file)
          })
        )
      })
      const files = await Promise.all(promises)
      onAddedImages(files)
    },
    [onAddedImages]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    maxFiles: 3,
    onDrop,
    maxSize: 2100000,
  })

  return (
    <div className="container">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p
          css={css`
            padding: 0px;
            margin: 0px;
            text-align: center;
          `}
        >
          <Trans>formDropbox</Trans>
        </p>
      </Container>
    </div>
  )
}

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676"
  }
  if (props.isDragReject) {
    return "#ff1744"
  }
  if (props.isDragActive) {
    return "#2196f3"
  }
  return "#747474"
}

const Container = styled.div`
  color: #747474;
  font-size: 0.9vw;
  font-family: "Bebas Neue";
  font-weight: 400;
  letter-spacing: 0.12vw;
  margin: 0px 0px 1.1vw 0px;
  height: 4vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  @media (max-width: 1024px) {
    /* height: 11vw; */
    /* font-size: 3vw; */
    /* margin: 0px 0px 2vw 0px; */
    display: none;
  }
`

const Form = styled.div`
  ${tw`relative flex flex-col z-10`}
  width: 50vw;
  padding: 2.5vw 4vw;
  background-color: #000000;
  @media (max-width: 1024px) {
    padding: 8vw 4vw;
    width: 100%;
  }
`

const FormInput = styled.input`
  ${tw`w-full box-border`}
  height: 2.8vw;
  background-color: #000000;
  border: 1px solid #3d3d3d;
  margin: 0px 0px 1.1vw 0px;
  font-size: 0.8vw;
  padding: 0px 1vw;
  color: #ffffff;
  ::placeholder {
    color: #747474;
    opacity: 1;
    font-family: "Bebas Neue";
    font-weight: 400;
    letter-spacing: 0.12vw;
  }
  @media (max-width: 1024px) {
    margin: 0px 0px 1.5vw 0px;
    padding: 2vw;
    font-size: 2.5vw;
    height: 7vw;
  }
  @media (max-width: 760px) {
    margin: 0px 0px 2vw 0px;
    padding: 2.7vw;
    font-size: 4vw;
    height: 9.2vw;
  }
`
const FormMessage = styled.textarea`
  ${tw`w-full box-border`}
  background-color: #000000;
  border: 1px solid #3d3d3d;
  margin: 0px 0px 1.1vw 0px;
  font-size: 0.8vw;
  height: 5vw;
  padding: 0.8vw 1vw;
  color: #ffffff;
  ::placeholder {
    color: #747474;
    opacity: 1;
    font-family: "Bebas Neue";
    font-weight: 400;
    letter-spacing: 0.12vw;
  }
  @media (max-width: 1024px) {
    margin: 0px 0px 1.5vw 0px;
    padding: 2vw;
    font-size: 2.5vw;
    height: 20vw;
  }
  @media (max-width: 760px) {
    margin: 0px 0px 2vw 0px;
    padding: 2.7vw;
    font-size: 4vw;
    height: 26.5vw;
  }
`
const SubmitButton = styled.button`
  ${tw`w-full`}
  border: 3px solid #ffffff;
  color: #ffffff;
  font-family: "Bebas Neue";
  font-weight: 500;
  letter-spacing: 0.1vw;
  font-size: 1.5vw;
  margin-left: 1vw;
  @media (max-width: 1024px) {
    font-size: 2.7vw;
    line-height: auto;
    height: 10vw;
    font-size: 3.5vw;
  }
  @media (max-width: 760px) {
    margin-top: 2vw;
    font-size: 2.7vw;
    line-height: auto;
    height: 10.5vw;
    font-size: 5vw;
  }
`

const FormInfo = styled.div`
  ${tw`flex flex-row w-full`}
  font-size: 0.5vw;
  color: #e7e7e7;
  line-height: auto;
  padding: 0px 2vw 0px 0px;
  @media (max-width: 1024px) {
    margin: 1vw 0vw 2vw 0vw;
    font-size: 1.5vw;
    line-height: auto;
  }

  @media (max-width: 760px) {
    margin: 1vw 0vw 2vw 0vw;
    font-size: 2.4vw;
    line-height: auto;
  }
`
